* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: #fff;
}

.chartStyle {
  background-image: url(./static/landing.svg);
  background-repeat: no-repeat;
  background-size: cover;
  text-decoration: none;
  height: 100%;
}
