/* styles go here */

.chart {
  padding: 2rem;
}

.chartName {
  text-align: center;
  margin-bottom: 1.5rem;
}

.chartName {
  font-size: 1.5rem;
}
